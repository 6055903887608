import { FEATURE_FLAG as CONSENT_FEATURE_FLAG } from '@buzzfeed/consent';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';

export default [
  CONSENT_FEATURE_FLAG,
  {
    name: 'DT-516-tasty_ui-time-spent-metrics-test',
    variations: ['control', 'time_spent_tracked'],
    isEligible: () => true
  },
  {
    name: 'RT-1559-AdShield-script-on-Tasty',
    variations: ['control', 'on'],
    isEligible: (buzz) => true,
  },
  {
    name: 'RT-1598_Chickory_ad_unit_Tasty',
    variations: ['control', 'chickory_on'],
    isEligible: () => {
      return getUserGeoCountry().toLowerCase() === 'us';
    }
  },
];
