import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import ErrorPage from 'next/error';
import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import {
  CLUSTER,
  PAGE_TYPES_ALL,
  NODE_ENV
} from '@/constants';
import { AnalyticsContext, RouteContext } from '@/context';
import TastyApi from '@/services/api/tasty';
import ABeagle from '../components/ABeagle';
import Ads from '../components/ads/Ads';
import Ad from '../components/ads/Ad';
import AdAwareness from '../components/ads/Ad/Awareness';
import ConsentManagement from '../components/ConsentManagement';
import PageTracking from '../components/PageTracking';
import Footer from '../components/footer/Footer';
import Nav from '../components/navbar/Nav/index';
import ResizeManager from '../components/ResizeManager';
import ScreenreaderContainer from '../components/screenreader';
import SvgSymbols from '../components/svg/SvgSymbols';
import { stripQueryParams, getToday } from '@/utils/misc-utils';
import { getResponseHeaders, getUrlOrigin } from '@/utils/http-utils';
import { ErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import '../utils/react-axe-setup';
import storage from '@buzzfeed/bf-utils/lib/storage';
import AdShieldScript from '../components/AdShieldScript';


import '../styles/main.scss';
import MyCartContext from '../components/OnlineGrocery/MyCartContext';

const isDev = CLUSTER === 'dev';

const scrollToTop = () => {
  const element = document.getElementById('__next');
  if (element) {
    element.scrollTop = 0;
    element.scrollLeft = 0;
  }
};

/**
 * Application is an override of the main Next application
 * it is required and it allows us to import a shared
 * "Head" element that includes Solid & Some Title Information..
 *
 * You can override this by including a different "Head" component
 * in further layouts
 */

async function fetchNavConfig() {
  try {
    // if there is an error with nav config (which should be very rare, as a good result is cached in proxy)
    //  catch it and return null to gracefully handle no nav instead of stopping the page from rendering
    const nav = await TastyApi.fetchNavConfig();
    return nav;
  } catch (e) {
    return null;
  }
}

class Application extends App {
  static async getInitialProps({ Component, ctx }) {
    /**
     * On client side when pager switches pages within app - this gets called before router
     *  document has registered location change.  Hook into this to update referrer, to avoid
     *  referrer always being whatever the first page hit was
     * Per NextJS - if wanting to know client side history for referrer without actually navigating
     *  back - need to track ourselves: https://github.com/zeit/next.js/issues/3565
     */
    const referrer = ctx.req ? '' : document.location.href;
    const [pageProps, navConfig] = await Promise.all([
      Component.getInitialProps ? Component.getInitialProps(ctx) : Promise.resolve({}),
      fetchNavConfig(),
    ]);

    if (ctx.res) {
      if (pageProps.statusCode) {
        ctx.res.statusCode = pageProps.statusCode;
      }
      // Check for no_cache override flag, and set max age TTL to 0 if it exists
      if (ctx.res.locals && ctx.res.locals.no_cache) {
        pageProps.maxAge = 0;
      }
      ctx.res.set(
        getResponseHeaders(pageProps, {
          statusCode: ctx.res.statusCode,
          requestHeaders: ctx.req.headers,
        })
      );
      // maybe more here - can do the bits with the page?
    } else if (CLUSTER !== 'dev' && pageProps.statusCode) {
      // When we have Client Side navigation errors we still want to be able to show
      // our custom 400/500 error pages. In order to this we can force a server side retry
      // which might succeed but if not will trigger the error pages to be loaded from the CDN
      Router.replace(ctx.asPath);
    }

    const pageType = pageProps.pageType || (ctx.pathname === '/' ? 'home' : ctx.pathname.substring(1));

    // only add certain fields for full pages, as other routes, like error can come through here
    if (PAGE_TYPES_ALL.includes(pageType)) {
      if (ctx.req) {
        pageProps.canonicalUrl = getUrlOrigin(ctx.req);
      } else {
        pageProps.canonicalUrl = window.location.origin;
      }
      pageProps.pagePath = stripQueryParams(ctx.asPath);
      pageProps.pageType = pageType;
    }


    if (pageProps.analyticsInfo) {
      pageProps.analyticsInfo.referrer = referrer;
    }

    return { pageProps, navConfig };
  }

  async componentDidMount() {
    // Handle the app download print cta and save in app banner logic
    const today = getToday();
    let printCTACountdown = JSON.parse(storage.get('print_cta_countdown'));
    if (printCTACountdown === null) {
      storage.set({ key: 'print_cta_countdown', value: 0});
    }

    const saveInAppCTACountdown = JSON.parse(storage.get('save_in_app_cta_countdown'));
    if (saveInAppCTACountdown === null) {
      storage.set({ key: 'save_in_app_cta_countdown', value: 0});
    }
    const lastSessionDate = storage.get('last_session_date');
    if (lastSessionDate === null) {
      storage.set({ key: 'last_session_date', value: today});
    }

    if (new Date(today) > new Date(lastSessionDate)) {
      storage.set({ key: 'print_cta_countdown', value: Math.max(printCTACountdown - 1, 0) });
      storage.set({ key: 'save_in_app_cta_countdown', value: Math.max(saveInAppCTACountdown - 1, 0) });
    }
    storage.set({ key: 'last_session_date', value: today});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageProps.pagePath !== this.props.pageProps.pagePath) {
      // path has changed.  Load any additional scripts
      stickyRegistry.reset();
      scrollToTop();
    }
  }

  render() {
    const { Component, pageProps, navConfig } = this.props;

    if (pageProps.statusCode) {
      if (NODE_ENV !== 'test' && CLUSTER === 'dev' && pageProps.error) {
        throw new Error(pageProps.error);
      }
      return <ErrorPage statusCode={pageProps.statusCode} />;
    }

    const { canonicalUrl, pagePath, pageType } = pageProps;

    // in dev cluster only, ignore render for a particular HMR js file
    if (isDev && pageProps.statusCode === 404 && this.props.router.asPath === '/ww.js.map') {
      return null;
    }

    if (pageType === 'home' && navConfig) {
      try {
        pageProps.shop = navConfig.primary.find((category) => category.key === 'shop');
      } catch (e) {
        console.error('Error finding shop in nav config');
      }
    }

    return (
      <RouteContext.Provider
        value={{
          canonicalUrl,
          pagePath,
          pageType,
          queryParams: this.props.router.query,
        }}
      >
        <ResizeManager>
          <AnalyticsContext.Provider value={{ ...pageProps.analyticsInfo }}>
            <ABeagle pageProps={pageProps}>
              <ConsentManagement />
              <AdShieldScript />
              <Ads page={pageProps}>
                <PageTracking pageProps={pageProps}>
                  <ErrorBoundary fallbackRender={() => <ErrorPage
                    statusCode={500}
                    title="An unexpected error has occurred"
                  />}
                  onError={captureException}
                  >
                    <ScreenreaderContainer/>
                    <SvgSymbols />
                    {pageType === 'in-app-only'
                      ? (<Component {...pageProps} key={`page-${pagePath}`}/>) : (
                        <>
                          <Ad type="pixel" />
                          <AdAwareness page={pageProps} />
                          <MyCartContext.Provider>
                            <Nav canonicalUrl={canonicalUrl} navConfig={navConfig} isCompact={pageType === 'recipe-buy'} />
                            <Component {...pageProps} key={`page-${pagePath}`}/>
                          </MyCartContext.Provider>
                          <Footer
                            key={`footer-${pagePath}`}
                            pageUrl={`${canonicalUrl}${pagePath}`}
                          />
                        </>
                      )}
                  </ErrorBoundary>
                </PageTracking>
              </Ads>
            </ABeagle>
          </AnalyticsContext.Provider>
        </ResizeManager>
      </RouteContext.Provider>
    );
  }
}

export default Application;
