import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import { CLUSTER, TASTY_URL } from '@/constants';
import { sizes, programmaticSizes } from '@buzzfeed/adlib/dist/module/services/size/constants';
import { isAdPost } from '@/utils/ad-utils';
import cookies from '@buzzfeed/bf-utils/lib/cookies';

export const getCMSTags = (page) => {
  const obj = page[page.pageType] || page;
  // articles
  if (page.pageType === 'article') {
    const laserTags = (
      obj.laser_tags &&
      obj.laser_tags.bf_content_description &&
      obj.laser_tags.bf_content_description.topic) ?
      obj.laser_tags.bf_content_description.topic : [];
    return [
      ...obj.tags.filter(tag => !/^--/.test(tag)), // cms tags, excluding special metadata tags
      ...laserTags.map(tag => tag.tag_name),
    ].filter((tag, index, tags) => tags.indexOf(tag) === index);
  }
  // all other page types
  let cmsTags = [];
  if (obj && obj.tags) {
    cmsTags = obj.tags.map((tag) => (tag.name || tag));
  }
  return cmsTags;
};

export const getAuthors = (content) => {
  return content?.credits?.names || (content?.bylines || []).map(author => author.display_name);
}

export function getPageContext({
  page,
  abeagle
}) {
  const pageType = page.pageType;
  const analyticsInfo = page.analyticsInfo || {};
  const userCountry = (cookies.get('country') || cookies.get('bf-geo-country') || 'us').toLowerCase();
  const env = {
    // constant values
    adSizes: sizes,
    programmaticSizes,
    destination: 'tasty',
    hasQuiz: false,
    isAsIs: false,
    isBFN: false,
    isBFO: false,
    isBPage: false,
    isDev: (CLUSTER === 'dev'),
    isE2ETest: false,
    isFeed: false,
    isFeedPage: false,
    isFeedpager: false,
    isHomePage: false,
    isNewsPost: false,
    isPharmaceutical: false,
    isProd: (CLUSTER === 'prod'),
    isWidePost: false,
    locale: 'en_US',
    localization: {
      country: 'en-us',
      language: 'en',
      locale: 'en_US',
      translations: {
        ADVERTISE_WITH_BUZZFEED: 'Advertise with BuzzFeed',
        ADVERTISE_WITH_URL: 'https://advertise.buzzfeed.com',
        ADVERTISEMENT: 'Advertisement',
        PAID_POST: 'Paid Post',
        PROMOTED_BY: 'Promoted By',
        PROMOTED: 'Promoted',
        SPONSORED_BY: 'Sponsored By',
      },
    },
    localizationCountry: 'en-us',
    userCountry,
    pageFilter: null,
    pageFilters: {},
    pageMainFilter: null,
    type: CLUSTER,
    webRoot: (CLUSTER === 'dev' ? 'https://stage.tasty.co' : TASTY_URL),

    // variable values
    allPageSections: ['Tasty', pageType],
    author: null,
    cmsTags: getCMSTags(page),
    isVideoRecipePage: page?.recipe?.non_video_recipe?.status === false,
    pageSection: pageType,
    pageCategory: pageType,
    pageName: pageType,
    // for cet perf tracking
    pageId: analyticsInfo.context_page_id ? analyticsInfo.context_page_id.toString() : pageType,
    analyticsPageType: analyticsInfo.context_page_type || pageType,

    isAdPost() {
      return isAdPost(page);
    },
  };

  return {
    env,
    abeagle,
    stickyRegistry,
  };
}
