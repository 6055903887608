import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { AnalyticsContext } from '@/context';
import { getConsentType } from '@/utils/consent-utils';
import FooterLinks from '../FooterLinks';
import SocialButtons from '../SocialButtons';
import SvgIcon from '../../svg/SvgIcon';
import AppBadge from '../../AppBadge';
import Newsletter from '../../Newsletter';
import tracking from '@/services/tracking';
import { SOURCEPOINT_PM_ID } from '@buzzfeed/consent';

const Footer = (props) => {
  const { pageUrl } = props;

  const consentType = getConsentType();
  const pageInfo = useContext(AnalyticsContext);

  const onConsentClick = (type) => {
    const sharedFields = {
      unit_type: 'bottom',
      unit_name: 'footer',
      position_in_unit: 0,
    };
    tracking.trackContentAction({
      eventInfo: {
        ...sharedFields,
        item_name: type === 'ccpa_update_consent' ? 'do_not_sell_my_info' : 'update_consent',
        item_type: type === 'ccpa_update_consent' ? 'button' : 'text',
        action_type: 'show',
        action_value: type,
      },
      pageInfo,
    });
    if (window._sp_) { // sourcepoint
      const spIntervalId = setInterval(() => {
        if (window._sp_.gdpr?.loadPrivacyManagerModal) {
          window._sp_.gdpr.loadPrivacyManagerModal(SOURCEPOINT_PM_ID);
          clearInterval(spIntervalId);
        }
      }, 500);
    }
  };

  const appBadgeTrackingFields = {
    position_in_unit: 0,
    unit_name: 'footer',
    unit_type: 'bottom',
  };

  return (
    <footer className="footer xs-z4 xs-mt4">
      <div className="footer__content md-flex">
        <div className="footer__column footer__column--left md-col-6">
          <SvgIcon
            className="footer__logo"
            id="tasty-logo"
            role="img"
            title="Tasty Logo footer"
          />
          {/* app badges on mobile */}
          <div className="footer__app-badges footer__app-badges--mobile md-hide xs-mb5 xs-text-3">
            <div className="extra-bold xs-mb1">Get the Tasty App</div>
            <div className="xs-flex">
              <AppBadge device="ios" className="xs-mr1" trackingFields={appBadgeTrackingFields} />
              <AppBadge device="android" trackingFields={appBadgeTrackingFields} />
            </div>
          </div>
          <div className="footer__newsletter xs-flex xs-flex-align-items">
            <Newsletter
              variant="inline"
              trackingFields={{
                gaDimension: 'footer',
                position_in_subunit: 0,
                position_in_unit: 0,
                subunit_name: 'newsletter_signup',
                subunit_type: 'component',
                unit_name: 'footer',
                unit_type: 'bottom',
              }}
            />
          </div>
          <div className="xs-mb4">
            <SocialButtons />
          </div>
          {/* copyright disclaimer on desktop */}
          <div className="footer__copyright xs-hide md-block">
            &copy; 2024 BuzzFeed, Inc
          </div>
        </div>
        <div className="footer__column footer__column--right md-col-6 md-flex">
          {/* app badges on desktop */}
          <div className="footer__app-badges footer__app-badges--desktop xs-hide md-block md-mb5 md-mt1">
            <AppBadge device="ios" className="xs-mr1" trackingFields={appBadgeTrackingFields}/>
            <AppBadge device="android" trackingFields={appBadgeTrackingFields} />
          </div>
          <ul className="footer__links-container">
            <FooterLinks pageUrl={pageUrl} />
            {/*
              GDPR consent prompt.  Visually appears like link, but acts like button
            */}
            { consentType === 'gdpr' &&
              <li>
                <button
                  className="footer__consent--gdpr footer-link ot-sdk-show-settings ot-sdk-btn"
                  id="ot-sdk-btn"
                  onClick={() => onConsentClick('gdpr_update_consent')}
                >
                  Update Consent
                </button>
              </li>
            }
          </ul>
          { consentType === 'ccpa' &&
              <div className="footer__consent--ccpa xs-mt4 md-my0">
                <button
                  className="footer__button ot-sdk-show-settings ot-sdk-btn"
                  id="ot-sdk-btn"
                  onClick={() => onConsentClick('ccpa_update_consent')}
                >
                  Do not sell or share my personal information
                </button>
              </div>
          }
          {/* copyright disclaimer on mobile */}
          <span className="footer__copyright xs-mb0 xs-mt4 xs-block md-hide">
            &copy; 2024 BuzzFeed, Inc
          </span>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  pageUrl: PropTypes.string.isRequired,
};

export default memo(Footer);
