import { sizes } from '@buzzfeed/adlib/dist/module/services/size/constants';


export const qa = {
  adops_giraffe: 'adops_giraffe',
  ads_qa: 'ads_qa',
  adtest: 'test',
  giraffe_test: 'giraffe_test',
};

const ZONE1_TASTY = 'tasty';

export const articleConfig = {
  /**
   * Awareness ads
   */
  'awareness-bp': {
    adType: 'awareness',
    adPos: 'awareness-bp',
    wid: 42,
    size: [
      sizes.NATIVE,
      sizes.FLUID,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.NATIVE_COMPLEX_6,
      sizes.NATIVE_COMPLEX_RECTANGLE
    ],
    noPrebid: true,
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Article - subbuzz inline ads - ALL breakpoints
    */
  'promo-inline1': {
    adType: 'ex',
    adPos: 'promo-inline1',
    wid: 270,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  'promo-inline2': {
    adType: 'ex',
    adPos: 'promo-inline2',
    wid: 280,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  'promo-inline3': {
    adType: 'ex',
    adPos: 'promo-inline3',
    wid: 281,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  'promo-inline4': {
    adType: 'ex',
    adPos: 'promo-inline4',
    wid: 282,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  'promo-inline-infinite': {
    adType: 'ex',
    adPos: 'promo-inline-infinite',
    wid: 260,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
    isInfinite: true,
  },

  /**
   * Article
   *  LG breakpoint: under feature CTA/above "More Tasty Guides"
   *  XS & SM breakpoints: under feature CTA/above Trending recipes
   */
  'promo-bottom1-lg': {
    adType: 'ex',
    adPos: 'promo-bottom1',
    wid: 200,
    size: [
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  'promo-bottom1': {
    adType: 'ex',
    adPos: 'promo-bottom1',
    wid: 201,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },


  /**
   * Article
   *  LG breakpoint: between "More Tasty Guides" content
   *  XS & SM breakpoints: under Trending recipes, above "More Tasty Guides"
   */
  'promo-bottom2-lg': {
    adType: 'ex',
    adPos: 'promo-bottom2',
    wid: 202,
    size: [
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  'promo-bottom2': {
    adType: 'ex',
    adPos: 'promo-bottom2',
    wid: 203,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
   * Article
   *  MD breakpoint: under feature/above more tasty guides, between more tasty guides
   *  XS & SM breakpoints: in latest guides and more tasty guides
   */
  'promo-bottom-infinite': {
    adType: 'ex',
    adPos: 'promo-bottom-infinite',
    wid: 204,
    size: [
      // for MD breakpoint
      sizes.PROGRAMMATIC_LEADERBOARD,
      // for XS/SM breakpoints
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.NATIVE_COMPLEX_100,
    ],
    zone1: ZONE1_TASTY,
    viewability: 'low',
    isInfinite: true,
  },

  /**
    * Article - desktop sidebar ads (MD/LG)
    */
  'sidebar1-bp': {
    adType: 'ex',
    adPos: 'sidebar1-bp',
    wid: 290,
    size: [
      sizes.NATIVE,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  'sidebar2-bp': {
    adType: 'ex',
    adPos: 'sidebar2-bp',
    wid: 291,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  'sidebar3-bp': {
    adType: 'ex',
    adPos: 'sidebar3-bp',
    wid: 292,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_VERTICAL,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  'sidebar4-bp': {
    adType: 'ex',
    adPos: 'sidebar4-bp',
    wid: 293,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_VERTICAL,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  'sidebar5-bp': {
    adType: 'ex',
    adPos: 'sidebar5-bp',
    wid: 294,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_VERTICAL,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },
};

export const config = {
  /**
   * Desktop - awareness ad - ALL page types except articles
   *  has possibility to be a spotlight (mobile spotlight)
   *  (Mobile spotlight handled in promo1-bp for all page types)
   */
  awareness: {
    adType: 'awareness',
    adPos: 'awareness',
    wid: 42,
    size: [
      sizes.NATIVE,
      sizes.FLUID,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.NATIVE_COMPLEX_6,
      sizes.NATIVE_COMPLEX_RECTANGLE,
    ],
    noPrebid: true,
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Desktop - sidebar ad 1 - for non video recipes
    *   Recipe - sidebar next to main image, for NON-video recipes only
    *   Compilation - N/A
    *   Home page - N/A
    */
  'sidebar1-bp-non-video': {
    adType: 'ex',
    adPos: 'sidebar1-bp',
    wid: 2010,
    size: [
      sizes.NATIVE,
      sizes.PROGRAMMATIC_VERTICAL,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  /**
    * Desktop - sidebar ad 1 - for video recipes
    *   Recipe - next to recirc unit towards bottom of page for video recipes
    *   Compilation - next to recirc unit towards bottom of page
    *   Home page - N/A
    */
  'sidebar1-bp': {
    adType: 'ex',
    adPos: 'sidebar1-bp',
    wid: 2010,
    size: [
      sizes.NATIVE,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_VERTICAL,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  /**
  * Desktop - sidebar ad 2
  *   Recipe - next to recirc unit towards bottom of page for NON-video recipes
  *   Compilation - N/A
  *   Home Page - N/A
  */
  'sidebar2-bp': {
    adType: 'ex',
    adPos: 'sidebar2-bp',
    wid: 2011,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.PROGRAMMATIC_VERTICAL,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  /**
    * Desktop - full width ad - LG breakpoints only
    *   Recipe - below ingredients/prep/nutrition and above recirc units
    *   Compilation - below video/title and above 'recipes in this video'
    *   Home Page - below above-the-fold content (feature package/recipe/article), above latest articles
    */
  'promo1-wide': {
    adType: 'ex',
    adPos: 'promo1-wide',
    wid: 220,
    size: [
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      sizes.PROGRAMMATIC_LEADERBOARD,
    ],
    supports: ['lg'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Desktop - full width ad - LG breakpoints only
    *   Recipe - below related recipes recirc and above tips/tasty guides
    *   Compilation - below compilation recipes/submit recipe CTA and above trending recipes
    *   Home Page - below latest recipes/shows content, above highlighted tips
    */
  'promo2-wide': {
    adType: 'ex',
    adPos: 'promo2-wide',
    wid: 225,
    size: [
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      sizes.PROGRAMMATIC_LEADERBOARD,
    ],
    supports: ['lg'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Desktop - infinite feed ad - LG breakpoints only
    *   Recipe - N/A
    *   Compilation - N/A
    *   Home page - below shows/best of tasty, above carousels
    *             - below more articles, above community recipes
    */
  'promo-wide-infinite': {
    adType: 'ex',
    adPos: 'promo-wide-infinite',
    wid: 250,
    size: [
      sizes.PROGRAMMATIC_BILLBOARD,
      sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
      sizes.PROGRAMMATIC_LEADERBOARD,
    ],
    supports: ['lg'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
    isInfinite: true,
  },

  /**
    * Desktop - Medium breakpoints only
    *   Recipe - below ingredients/prep/nutrition and above recirc units
    *   Compilation - below video/title and above 'recipes in this video'
    *   Home Page - below above-the-fold content (feature package/recipe/article), above latest articles
    */
  'promo-inline1': {
    adType: 'ex',
    adPos: 'promo-inline1',
    wid: 270,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.NATIVE_COMPLEX_100,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Desktop - Medium breakpoints only
    *   Recipe - below related recipes recirc and above tips/tasty guides
    *   Compilation - below compilation recipes/submit recipe CTA and above trending recipes
    *   Home Page - below latest recipes/shows content, above highlighted tips
    */
  'promo-inline2': {
    adType: 'ex',
    adPos: 'promo-inline2',
    wid: 280,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.NATIVE_COMPLEX_100,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },
  /**
    * Desktop - Medium and Large breakpoints only
    *   Recipe - below tips/tasty guides and above carouselFeeds.carousels
    */
  'promo-inline3': {
    adType: 'ex',
    adPos: 'promo-inline3',
    wid: 281,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.NATIVE_COMPLEX_100,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Desktop - Medium and Large breakpoints only
    *   Recipe - below carouselFeeds.carousels and above ArticleFeed
    */
  'promo-inline4': {
    adType: 'ex',
    adPos: 'promo-inline4',
    wid: 282,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.NATIVE_COMPLEX_100,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'high',
  },

  /**
    * Desktop - Medium breakpoints only - infinite feed ads
    *   Recipe - N/A
    *   Compilation - N/A
    *   Home page - below shows/best of tasty, above carousels
    *             - below more articles, above community recipes
    */
  'promo-inline-infinite': {
    adType: 'ex',
    adPos: 'promo-inline-infinite',
    wid: 260,
    size: [
      sizes.PROGRAMMATIC_LEADERBOARD,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.NATIVE_COMPLEX_100,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
    isInfinite: true,
  },

  /**
  * Mobile feed ad - with possibility to be spotlight (image or video)
  *   Recipe - below recipe video/image
  *   Compilation - below video
  *   Home Page - below featured recipe, above featured article
  *   Feature page - below header with sections
  */
  'promo1-bp': {
    adType: 'ex',
    adPos: 'promo1-bp',
    wid: 210,
    size: [
      sizes.NATIVE,
      sizes.FLUID,
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Mobile - feed ad
    *   Recipe - below nutrition (or ingredients if no nutrition), above preparation
    *   Compilation - below submit CTA and above trending recirc
    *   Home Page - below featured package, above latest recipes
    */
  'promo2-bp': {
    adType: 'ex',
    adPos: 'promo2-bp',
    wid: 211,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Mobile - feed ad
    *   Recipe - After prep step 3 (IF there are at least 6 preparation steps)
    *   Compilation - below bottom of feeds, above newsletter component
    *   Home Page - below latest articles, above stories (reels)
    */
  'promo3-bp': {
    adType: 'ex',
    adPos: 'promo3-bp',
    wid: 212,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Mobile - feed ad
    *   Recipe - After prep step 6 (IF there are at least 8 preparation steps)
    *   Compilation - N/A
    *   Home Page - below featured tips, above shows
    */
  'promo4-bp': {
    adType: 'ex',
    adPos: 'promo4-bp',
    wid: 213,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  'promo5-bp': {
    adType: 'ex',
    adPos: 'promo5-bp',
    wid: 214,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Mobile - infinite feed ad
    *   Compilation - N/A
    *   Recipe - Between tips 3 and 4 (IF there are 6 tips displayed)
    *   Home Page - below shows, above best of tasty
    *             - in middle of carousels (in between carousels 2 and 3)
    *             - below more articles, above community recipes
    */
  'promo-infinite-bp': {
    adType: 'ex',
    adPos: 'promo-infinite-bp',
    wid: 240,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
    isInfinite: true,
  },

  /**
    * Mobile - bottom of feed ad
    *   Recipe - After tips (or preparation if no tips), above related/trending
    *   Compilation - N/A
    *   Home Page - N/A
    */
  'promo-bottom1': {
    adType: 'ex',
    adPos: 'promo-bottom1',
    wid: 200,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.NATIVE_COMPLEX_100,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /**
    * Mobile - bottom of feed ad
    *   Recipe - Below related/trending, above tasty guides
    *   Compilation - N/A
    *   Home Page - N/A
    */
  'promo-bottom2': {
    adType: 'ex',
    adPos: 'promo-bottom2',
    wid: 201,
    size: [
      sizes.PROGRAMMATIC_MEDIUM_RECTANGLE,
      sizes.NATIVE_COMPLEX_100,
    ],
    supports: ['xs', 'sm'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },

  /** Desktop/Mobile - impression pixel
    *   Recipe - only on branded recipes
    *   Compilation - N/A
    *   Home Page - N/A
    */
  pixel: {
    adType: 'post',
    adPos: 'pixel',
    wid: 0,
    size: [sizes.RESEARCH_PIXEL],
    zone1: ZONE1_TASTY,
  },
  toolbar: {
    adType: 'toolbar',
    adPos: 'tb',
    wid: 52,
    size: [
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER,
      sizes.PROGRAMMATIC_SMARTPHONE_BANNER_WIDE,
      sizes.COMPLEX_XS_BANNER,
    ],
    supports: ['md', 'lg'],
    zone1: ZONE1_TASTY,
    viewability: 'low',
  },
};
